.App {
  text-align: center;
  height: 100vh;
  background: linear-gradient(#005BBB 50%, #FFD500 50%);
}

svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

svg .slices-group {
  transform: rotate(0deg);
  transition: transform ease-out 1.5s;
}
.App.ready svg .slices-group {
  transform: rotate(900deg);
}

svg textPath {
  transition: opacity ease-out 1.5s 1s;
  opacity: 0;
}

.App.ready svg textPath {
  opacity: 1;
}

svg .slicePath {
  transition: d ease 2s;
  filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .4));
}

.App .image {
  transition: opacity ease-out 1s;
  opacity: 0;
}

.App.ready .image {
  opacity: 1;
}

@media (orientation: landscape) {
  svg {
    height: 100vh;
  }
}

@media (orientation: portrait) {
  svg {
    width: 100vw;
  }
}
